<template>
  <div class="search">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-row type="flex" justify="space-between" align="middle" style=""> <!-- border-bottom: 2px solid #E4E7ED -->
        <el-col :span="14" style="height: 55px">
          <el-form-item v-if="term">
            <el-select
              v-model="queryForm.term"
              placeholder="学期"
              style="width: 188px"
              @change="handleChange"
            >
              <el-option v-for="item,index in termlist" :key="index" :label="item.termName" :value="item.termName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="isClass">
            <el-select
              v-model="queryForm.deptId"
              placeholder="班级"
              style="width: 120px"
              @change="handleDeptChange"
            >
              <el-option v-for="item,index in classlist" :key="index" :label="item.deptName" :value="item.deptId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="status">
            <el-select v-model="queryForm.breakStatus" placeholder="全部状态" style="width: 95px" clearable>
                <el-option label="存在" value="0"></el-option>
                <el-option label="已撤销" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="constitution">
            <el-select v-model="queryForm.userStatus" placeholder="学生状态" style="width: 95px" clearable>
                <el-option v-for="item in stuStatus" :key="item.id" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="testStatus">
            <el-select v-model="queryForm.evaStatus" placeholder="测评状态" style="width: 95px" clearable>
                <el-option label="已测评" value="已测评"></el-option>
                <el-option label="未测评" value="未测评"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="isSocial">
            <el-select v-model="queryForm.practiceType" placeholder="实践类别" style="width: 95px" clearable>
                <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="isSocial">
            <el-select v-model="queryForm.practiceLabel" placeholder="指标标签" style="width: 95px" clearable>
              <el-option v-for="item in labelList" :key="item.id" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="isSocial">
            <el-select v-model="queryForm.publishStatus" placeholder="发布状态" style="width: 95px" clearable>
              <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="isExperience">
            <el-select v-model="queryForm.submitStatus" placeholder="提交状态" style="width: 95px" clearable>
              <el-option v-for="item in perSubmitStatusList" :key="item.id" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="isExperience">
            <el-select v-model="queryForm.verifyStatus" placeholder="感悟状态" style="width: 95px" clearable>
              <el-option v-for="item in perStatusList" :key="item.id" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10" style="height: 55px;text-align: right">
          <el-form-item v-if="isSearch">
            <el-input
              v-model="queryForm.userName"
              placeholder="输入学生姓名/学籍号"
              style="width: 283px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item v-if="isBtn">
            <el-button type="primary" @click="handleQuery">搜索</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {getTerm,getClass,getDic} from '@/api/public/search'
import {getStudentStatus} from '@/api/teacher/quality/health'
export default {
  data() {
    return {
      queryForm: {
        userName: "",
        term: this.termName,
        deptId: this.classId,
        breakStatus: "",
        userStatus: "",
        evaStatus: "",
        practiceType: "",
        practiceLabel: "",
        publishStatus: "",
      },
      stuStatus: [],
      // termlist: [],
      // classlist: [],
      typeList: [],
      labelList: [],
      statusList: [],
      perSubmitStatusList: [],
      perStatusList: [],
    };
  },
  props: {
    // typeId: {
    //   type: String,
    //   default: ''
    // },
    termlist: {
      type: Array,
      default: () => []
    },
    classlist: {
      type: Array,
      default: () => []
    },
    // termName: {
    //   type: String,
    //   default: '',
    // },
    // classId: {
    //   type: String,
    //   default: '',
    // },
    term: {
      type: Boolean,
      default: true,
    },
    isClass: {
      type: Boolean,
      default: true,
    },
    status: {
      type: Boolean,
      default: false
    },
    constitution: {
      type: Boolean,
      default: false
    },
    testStatus: {
      type: Boolean,
      default: false
    },
    isSearch: {
      type: Boolean,
      default: true,
    },
    isBtn: {
      type: Boolean,
      default: true,
    },
    isSocial: {
      type: Boolean,
      default: false,
    },
    isExperience: {
      type: Boolean,
      default: false,
    }
  },
  created() {
    if(this.constitution){
      this.getStuStatus()
    }
  },
  methods: {
    handleChange(term) {
      this.$emit('handleChange', term)
    },
    handleDeptChange(deptId) {
      this.$emit('handleDeptChange', deptId)
    },
    handleQuery(){
      this.$emit('handleQuery', this.queryForm)
    },
    init(term,deptId){
      this.queryForm.term = term
      this.queryForm.deptId = deptId
    },
    getStuStatus() {
      getStudentStatus({type: 'userStatus'}).then(res => {
        // console.log(res,'status');
        this.stuStatus = res.data
      })
    },
    getDicByPractice() {
      getDic({type: 'practiceType'}).then(res => {
        this.typeList = res.data
      })
      getDic({type: 'practiceLabel'}).then(res => {
        this.labelList = res.data
      })
      getDic({type: 'practiceStatus'}).then(res => {
        this.statusList = res.data
      })
    },
    getDicBySocial() {
      getDic({type: 'perSubmitStatus '}).then(res => {
        this.perSubmitStatusList = res.data
      })
      getDic({type: 'perStatus'}).then(res => {
        this.perStatusList = res.data
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.search{
    // margin-bottom: 20px;
    margin-bottom: 10px;
}
</style>
<style scoped>
.el-select >>> .el-input--suffix .el-input__inner {
  padding-left: 5px !important;
}
</style>