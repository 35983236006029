<template>
  <div class="container">
    <div class="user-nav">
      <Search
        :isSearch="false"
        :isBtn="false"
        :termlist="termlist"
        :classlist="classlist"
        ref="search"
        @handleChange="handleChange"
        @handleDeptChange="handleDeptChange"
      />
    </div>
    <div class="main">
      <div class="table-box">
        <h4>综素工作完成进度</h4>
        <table border="1" cellspacing="0" rules="all">
          <tr>
            <th style="width: 5%">序号</th>
            <th>综素工作项</th>
            <th>应完成</th>
            <th>已完成</th>
            <th>未完成</th>
            <th>完成进度</th>
          </tr>
          <tr>
            <td>1</td>
            <td>写实记录审核（条数）</td>
            <td>{{ progressForm.type200Plan }}</td>
            <td>{{ progressForm.type200Done }}</td>
            <td>{{ progressForm.type200Undone }}</td>
            <td>{{ progressForm.type200Schedule }}</td>
          </tr>
          <tr>
            <td>2</td>
            <td>社会实践发布（条数）</td>
            <td>{{ progressForm.type341Plan }}</td>
            <td>{{ progressForm.type341Done }}</td>
            <td>{{ progressForm.type341Undone }}</td>
            <td>{{ progressForm.type341Schedule }}</td>
          </tr>
          <tr>
            <td>3</td>
            <td>期中成绩维护（人数）</td>
            <td>{{ progressForm.typeMiddlePlan }}</td>
            <td>{{ progressForm.typeMiddleDone }}</td>
            <td>{{ progressForm.typeMiddleUndone }}</td>
            <td>{{ progressForm.typeMiddleSchedule }}</td>
          </tr>
          <tr>
            <td>4</td>
            <td>期末成绩维护（人数）</td>
            <td>{{ progressForm.typeFinalPlan }}</td>
            <td>{{ progressForm.typeFinalDone }}</td>
            <td>{{ progressForm.typeFinalUndone }}</td>
            <td>{{ progressForm.typeFinalSchedule }}</td>
          </tr>
          <tr>
            <td>5</td>
            <td>体质检测成绩维护（人数）</td>
            <td>{{ progressForm.type403Plan }}</td>
            <td>{{ progressForm.type403Done }}</td>
            <td>{{ progressForm.type403Undone }}</td>
            <td>{{ progressForm.type403Schedule }}</td>
          </tr>
          <tr>
            <td>6</td>
            <td>心理测评（人数）</td>
            <td>{{ progressForm.type400Plan }}</td>
            <td>{{ progressForm.type400Done }}</td>
            <td>{{ progressForm.type400Undone }}</td>
            <td>{{ progressForm.type400Schedule }}</td>
          </tr>
          <tr>
            <td>7</td>
            <td>健康知识测评（人数）</td>
            <td>{{ progressForm.type401Plan }}</td>
            <td>{{ progressForm.type401Done }}</td>
            <td>{{ progressForm.type401Undone }}</td>
            <td>{{ progressForm.type401Schedule }}</td>
          </tr>
          <tr>
            <td>8</td>
            <td>艺术知识测评（人数）</td>
            <td>{{ progressForm.type402Plan }}</td>
            <td>{{ progressForm.type402Done }}</td>
            <td>{{ progressForm.type402Undone }}</td>
            <td>{{ progressForm.type402Schedule }}</td>
          </tr>
          <tr>
            <td>9</td>
            <td>课堂表现评价（人数）</td>
            <td>{{ progressForm.type478Plan }}</td>
            <td>{{ progressForm.type478Done }}</td>
            <td>{{ progressForm.type478Undone }}</td>
            <td>{{ progressForm.type478Schedule }}</td>
          </tr>
          <tr>
            <td>10</td>
            <td>体育出勤记录维护（人数）</td>
            <td>{{ progressForm.type466Plan }}</td>
            <td>{{ progressForm.type466Done }}</td>
            <td>{{ progressForm.type466Undone }}</td>
            <td>{{ progressForm.type466Schedule }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/Search";
import {getData} from '@/api/teacher/quality/progress'
export default {
  data() {
    return {
      termlist: [],
      classlist: [],
      queryParams: {},
      progressForm: {},
    };
  },
  components: { Search },
  created() {
    this.getFirst();
  },
  methods: {
    getFirst() {
      this.$store.dispatch("GetTermList").then((res) => {
        this.termlist = res.data;
      });
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.classlist = res.data;
        this.queryParams.term = this.$store.getters.queryForm.termName;
        this.queryParams.deptId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init(
          this.queryParams.term,
          this.queryParams.deptId
        );
        this.getList();
      });
    },
    getList() {
      getData(this.queryParams).then(res => {
        this.progressForm = res.data
      })
    },
    handleChange(data) {
      this.queryParams.term = data
      this.getList()
    },
    handleDeptChange(data){
      this.queryParams.deptId = data
      this.getList()
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
.container {
  .user-nav {
    padding: 10px 12px;
  }
  .main {
    .table-box {
      table {
        tr {
          th {
            width: 16%;
          }
        }
      }
    }
  }
}
</style>